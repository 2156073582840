<template>
  <Container>
    <v-card-text>
      <div class="d-flex align-center justify-center">
        <v-progress-circular
          v-if="loading"
          color="primary"
          indeterminate
        />
      </div>
      <template v-if="!loading">
        <p
          v-if="success"
          class="mb-2"
        >
          <v-icon
            color="success"
            left
          >
            mdi-check-circle-outline
          </v-icon>
          <span class="text-subtitle-2">Xác nhận thành công</span>
        </p>
        <p
          v-else
          class="mb-2"
        >
          <v-icon
            color="error"
            left
          >
            mdi-close-circle-outline
          </v-icon>
          <span class="text-subtitle-2">Đã có lỗi xảy ra</span>
        </p>

        <v-btn
          v-if="success"
          href="/login"
          block
          color="primary"
          class="mt-6"
        >
          <span>Đăng nhập</span>
        </v-btn>
      </template>
    </v-card-text>
  </Container>
</template>

<script>

import Container from '../components/Container.vue'

export default {
  components: {
    Container,
  },

  data() {
    return {
      errors: {},
      message: '',
      loading: true,
      success: false,
      password: '',
      cPassword: '',
    }
  },

  computed: {
    id() {
      return this.$route.params.id
    },
    hash() {
      return this.$route.params.hash
    },
    expires() {
      return this.$route.params.expires
    },
    signature() {
      return this.$route.params.signature
    },
  },

  created() {
    const payload = { // don't change the params order
      expires: this.expires,
      hash: this.hash,
      id: this.id,
      signature: this.signature,
    }
    this.$http.get('/api/auth/user/verify', { params: payload })
      .then(() => {
        this.success = true
      })
      .catch(err => {
        if (err.response.data.errors) {
          this.errors = err.response.data.errors
        } else if (err.response.data.message) {
          this.message = err.response.data.message
        } else {
          this.message = 'Something is wrong!'
        }
      })
      .finally(() => {
        this.loading = false
      })
  },
}
</script>
